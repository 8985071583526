@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .w-30 {
    width: 43%;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
@layer components {
  .ActiveButton {
    @apply bg-[#0B141A]/10 flex h-10 w-10 cursor-pointer items-center justify-center rounded-full;
  }
  .noActiveButton {
    @apply flex h-10 w-10 cursor-pointer items-center justify-center rounded-full;
  }
}
.dropdown {
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
}

.dropdown.active {
  opacity: 1;
}
.custom-scrollbar {
  scrollbar-color: #303d45 transparent;
  scrollbar-width: thin;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 3px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  height: 10px;
  border-radius: 3px;
  background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

button.ant-btn-primary,
[type="button"].ant-btn-primary,
[type="reset"].ant-btn-primary,
[type="submit"].ant-btn-primary {
  background-color: theme("colors.secondary");
}

button.ant-btn-primary:disabled,
[type="button"].ant-btn-primary:disabled,
[type="reset"].ant-btn-primary:disabled,
[type="submit"].ant-btn-primary:disabled {
  background-color: rgba(0, 0, 0, 0.04);
}

.ant-select-multiple .ant-select-selection-overflow-item {
  max-width: 95%;
}

button.ant-switch:not(.ant-switch-checked):not(:hover),
[type="button"].ant-switch:not(.ant-switch-checked):not(:hover) {
  background-color: rgba(0, 0, 0, 0.25);
}

/* For WebKit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
  width: 12px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Track (background) color */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Scrollbar thumb color */
  border-radius: 10px; /* Rounded corners for the thumb */
  border: 2px solid #f1f1f1; /* Space around the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Change color on hover */
}

/* Custom scrollbar  */
body {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.ant-steps-icon {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* custom input button */

/* .input-icon-height button {
  height: 40px !important;
} */
