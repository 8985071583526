html,
body {
  overflow: hidden !important;
}

.ant-menu-submenu a {
  color: rgba(0, 0, 0, 0.85);
}

.ant-menu-submenu a:hover .ant-menu-submenu-title a {
  color: #1e48f7;
}

.ant-modal-wrap {
  z-index: 1020;
}

.ant-notification-notice.ant-notification-notice-warning.ant-notification-notice-closable {
  cursor: pointer;
}
.ant-tooltip-content a {
  color: white;
}

.ant-tooltip-content a:hover {
  color: white;
}

a:hover {
  color: #1e48f7;
}


.disabled-row {
  background-color: #9d949421 !important;
  pointer-events: none !important;
}

.boradcast-contact {
  .ant-upload.ant-upload-btn{
    height: 106px;
    border:1px dashed #1E48F7;
    background-color: #1E48F71A;
  }
}


.font-iter-sans {
  font-family: "Inter", sans-serif !important;
  /* font-optical-sizing: auto; */
}